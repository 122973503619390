
// Import all mixins, and the master file with variable definitions etc.
@import "/home/travis/build/ChromatixAU/client-hruc/src/sass/abstracts/_master.scss";
@import "/home/travis/build/ChromatixAU/client-hruc/src/sass/abstracts/_mixins.scss";
@import "/home/travis/build/ChromatixAU/client-hruc/src/sass/abstracts/mixins/_button.scss";
@import "/home/travis/build/ChromatixAU/client-hruc/src/sass/abstracts/mixins/_images.scss";
@import "/home/travis/build/ChromatixAU/client-hruc/src/sass/abstracts/mixins/_layouts.scss";
@import "/home/travis/build/ChromatixAU/client-hruc/src/sass/abstracts/mixins/_typography.scss";

.login h1 a {
  width: 320px;
  height: 73px;
  background-image: url(chromatix.svg);
  background-size: contain;
}
